import './image-hotspot.scss';
import { createElementFromHTML } from '../../js/helper';

class ImageHotspot {
    constructor ($hotspot) {
        this.$hotspot = $hotspot;
        this.headline = this.$hotspot.getAttribute('data-image-hotspot-headline');
        this.qrcode = this.$hotspot.getAttribute('data-image-hotspot-qrcode');
        this.name = this.$hotspot.getAttribute('data-image-hotspot-name');
        this.subline = this.$hotspot.getAttribute('data-image-hotspot-subline');
        this.buttonlabel = this.$hotspot.getAttribute('data-image-hotspot-buttonlabel');
        this.url = this.$hotspot.getAttribute('data-image-hotspot-url');
        this.$overlay = null;
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$hotspot.addEventListener('click', () => {
            if (this.$overlay) {
                this.openOverlay();
            } else {
                if (document.documentElement.classList.contains('no-csshover')) {
                    if (this.$hotspot.getAttribute('data-image-hotspot-mobileheadline') !== '') {
                        this.headline = this.$hotspot.getAttribute('data-image-hotspot-mobileheadline');
                    }
                }

                this.createOverlay(() => {
                    this.addCloseEvent();
                    setTimeout(() => {
                        this.openOverlay();
                    }, 200);
                });
            }
        });
    }

    createOverlay (callback) {
        const overlayHTML = '<div class="image-hotspot__overlay"><div class="image-hotspot__overlay-container">' +
            '<span class="image-hotspot__overlay-close icon-close" data-image-hotspot-close><i>Close</i></span>' +
            '<div class="image-hotspot__overlay-content">' +
            '<h4 class="image-hotspot__overlay-headline">' + this.headline + '</h4>' +
            '<figure class="image-hotspot__overlay-qrcode">' + this.qrcode + '</figure>' +
            '<a href="' + this.url + '" class="cta image-hotspot__overlay-cta" target="_blank">' + this.buttonlabel + '</a>' +
            '<p class="image-hotspot__overlay-subline">' + this.subline + '</p></div></div></div>';

        this.$overlay = createElementFromHTML(overlayHTML);
        document.body.appendChild(this.$overlay);

        if (typeof callback === 'function') {
            callback();
        }
    }

    addCloseEvent () {
        this.$overlay.querySelector('[data-image-hotspot-close]')?.addEventListener('click', () => {
            this.$overlay.classList.remove('is--active');
        });
    }

    openOverlay () {
        this.$overlay.classList.add('is--active');
    }
}

export { ImageHotspot };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $hotSpots = $context.querySelectorAll('[data-image-hotspot]');

        if ($hotSpots.length > 0) {
            $hotSpots.forEach(($hotSpot) => {
                const hotSpot = new ImageHotspot($hotSpot);
                hotSpot.initialize();
            });
        }
    }
});
